<template>

    <nav id="nav-user">
        <ul>

            <!-- guest (when logged out) -->
            <li v-if="isGuest" class="nav-item">
                <a
                    :href="route('login')"
                    :title="trans('tooltips.navigation.login')"
                    class="nav-link btn btn-login"
                >
                    {{ trans('labels.login') }}
                </a>
            </li>

            <!-- logged in user -->
            <li v-else class="nav-item user-menu">

                <!-- User -->
                <div
                    id="navbarDropdown"
                    :title="trans('tooltips.navigation.user')"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    tabindex="0"
                >

                    <!-- Profile image -->
                    <div class="user-image-crop">
                        <img :src="user!.image" alt="" class="user-image">
                    </div>

                    <!-- User name -->
                    <span class="username">{{ userName }}</span>

                    <!-- User role -->
                    <div class="user-rank">
                        <span :title="tenantNameTooltip">{{ tenantName }}</span>
                    </div>

                </div>

                <!-- extended menu items -->
                <div
                    aria-labelledby="navbarDropdown"
                    class="dropdown-menu dropdown-menu-right"
                >
                    <!-- Switch Tenants -->
                    <a
                        v-if="canSwitchTenants"
                        :title="trans('labels.change_tenant')"
                        class="dropdown-item"
                        href="#"
                        @click="onSwitchTenantClick"
                    >
                        {{ trans('labels.change_tenant') }}
                    </a>

                    <!-- Edit Profile -->
                    <a
                        v-if="canUpdateOwnUser"
                        :href="route('users.updateForm', {'user': user!.uid})"
                        :title="trans('tooltips.navigation.edit_profile')"
                        class="dropdown-item"
                    >
                        {{ trans('labels.edit_profile') }}
                    </a>

                    <!-- Logout -->
                    <a
                        :href="route('logout')"
                        :title="trans('tooltips.navigation.logout')"
                        class="dropdown-item"
                    >
                        {{ trans('labels.logout') }}
                    </a>

                </div>

            </li>

        </ul>
    </nav>

</template>

<script lang="ts">

// Import classes:
import {permission, route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import {defineComponent, inject} from 'vue';
import EventType from '@/Utility/EventType';
import type Tenant from '@/Models/Tenant/Tenant';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';

export default defineComponent({

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
        };
    },

    computed: {
        isGuest() {
            return !window.currentUser;
        },

        user() {
            return window.currentUser;
        },

        canSwitchTenants() {
            return this.$gate.allows(Permission.TenantsReadAny());
        },

        canUpdateOwnUser() {
            return permission(Permission.UsersUpdateOwn());
        },

        userName() {
            let firstNameLetter = '';

            if (this.user?.firstname) {
                firstNameLetter += this.user?.firstname[0] + '. ';
            }

            return firstNameLetter + this.user?.lastname;
        },

        tenantName() {
            return this.user?.tenant?.name;
        },

        tenantRole() {
            if (!this.user?.tenant_role) {
                throw new Error('user and tenant role must be set');
            }

            return trans('tenant_roles.' + this.user.tenant_role.name);
        },

        tenantNameTooltip() {
            return `${this.tenantName} (${this.tenantRole})`;
        },
    },

    methods: {
        route,
        trans,

        onSwitchTenantClick() {
            this.$globalEvents.once(EventType.MODAL_SELECT_TENANT_APPLY, this.onTenantSelected);
            this.$globalEvents.once(EventType.MODAL_SELECT_TENANT_CANCEL, this.removeTenantSelectionModalListeners);

            this.$globalEvents.emit(EventType.MODAL_SELECT_TENANT_SHOW);
        },

        removeTenantSelectionModalListeners() {
            this.$globalEvents.off(EventType.MODAL_SELECT_TENANT_APPLY, this.onTenantSelected);
            this.$globalEvents.off(EventType.MODAL_SELECT_TENANT_CANCEL, this.removeTenantSelectionModalListeners);
        },

        onTenantSelected(tenant: Tenant) {
            this.removeTenantSelectionModalListeners();

            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.changing_tenant'));

            this.tenantService
                .switchTenant(tenant)
                .then(() => {
                    // reload page to update references
                    window.location.reload();
                })
                .catch(this.onErrorApi);
        },

        onErrorApi(error: string | Error) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);

            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }

            this.$root?.showErrorDialog(error);
        },
    },
});
</script>

<style lang="scss" scoped>

</style>
