<template>
    <main
        id="layout-main"
        v-shortcuts
        :data-loading="isLoading"
        :data-saving="isSaving"
        class="course-edit"
    >

        <PageHeader
            :page-title="pageHeadline"
            :page-subtitle="pageSubtitle"
            :buttons="headerButtons"
            :labels="pageHeaderLabels"
        />

        <div id="layout-content">
            <div v-if="course !== null"
                 id="content"
                 ref="content"
                 v-not-focusable>

                <div
                    v-if="!hasUnits"
                    class="no-units">
                    <p>{{ trans('courses.view.course_empty') }}</p>
                </div>

                <div ref="unitList" class="unit-list">
                    <UnitListItem
                        v-for="unit in unitsInCourse"
                        :key="'unit_' + unit.uid"
                        :unit="unit"
                        :current-unit-revision-uid="unit.latest_released_revision_uid"
                        :unit-is-removable="false"
                        :course="course"
                        :selected="(selectedUnit && selectedUnit.uid === unit.uid)"
                        @focusout="onBlurUnit"
                        @focus="onClickUnit"
                        @click="onClickUnit"
                        @click-launch="onClickUnitLaunch"
                        @dblclick="onClickUnitLaunch"
                    />
                </div>

                <ButtonSecondary
                    v-if="this.courseIsEditable"
                    v-tooltip="'buttons.courses.edit'"
                    :caption="trans('labels.edit')"
                    class="btn-edit-unit"
                    icon="icon_edit"
                    :href="route('courses.edit', {'course': this.course.uid})"
                />

            </div>

            <aside
                :key="'aside_unit_' + key + '_' + shouldShowUnitSidepanel"
                id="layout-inspector"
                ref="inspector"
                :class="{ 'open': selectedUnit }"
                v-if="selectedUnit"
            >
                <SidepanelViewUnit
                    v-if="shouldShowUnitSidepanel"
                    :key="'panel-unit-'+selectedUnit.uid+'_' + shouldShowUnitSidepanel"
                    :unit="selectedUnit"
                    :course="course"
                    :showAuthorAssignment="false"
                />
            </aside>

            <aside
                :key="'aside_course_' + key"
                v-else-if="course !== null && !shouldShowUnitSidepanel"
                id="layout-inspector"
                ref="inspector"
                class="open"
            >
                <SidepanelViewCourse
                    :course="course"
                    :show-user-enrollments="false"
                />
            </aside>

            <ModalProgress/>
            <ModalNotification/>
        </div>
    </main>
</template>

<script>
    // Components
    import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
    import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
    import PageHeader from "@/Vue/Common/PageHeader.vue";
    import SidepanelViewCourse from "@/Vue/Sidepanel/SidepanelViewCourse";
    import SidepanelViewUnit from "@/Vue/Sidepanel/SidepanelViewUnit.vue";
    import UnitListItem from "@/Vue/Courses/UnitListItem";

    // Classes
    import EventType from "@/Utility/EventType";
    import moment from 'moment';
    import AuthorizationError from "@/Errors/AuthorizationError";
    import {route, shortId, trans} from '@/Utility/Helpers';
    import {Permission} from "@/Models/User/Permission";
    import PageHeaderButton from "@/Utility/PageHeaderButton";
    import {UnitPermissionPolicyStandard} from "@/Models/Unit/UnitPermissionPolicy";
    import StatusLabelConfig from "@/Utility/StatusLabelConfig";
    import Unit from "@/Models/Unit/Unit";
    import {Feature} from "@/Models/Features/Feature";
    import {inject} from "vue";
    import {
        courseServiceKey,
        featureRepositoryKey,
        unitServiceKey,
        userServiceKey
    } from "@/Vue/Bootstrap/InjectionKeys";
    import ModalProgress from "@/Vue/Modals/ModalProgress.vue";
    import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';

    export default {

        name: 'PageCourseView',

        components: {
            ButtonSecondary,
            ModalProgress,
            PageHeader,
            SidepanelViewUnit,
            SidepanelViewCourse,
            ModalApplyCancel,
            ModalNotification,
            UnitListItem,
        },

        props: {
            initialCourseUid: {
                type: String,
                default: null
            }
        },

        mounted() {
            // fetch full course from api
            this.fetchCourse();

            // this.userService.fetchUsers();

            // Add global events:
            this.$globalEvents.on(EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav);
            this.$globalEvents.addEvent('click.global.course-unit-list', this.onClickGlobal);
        },

        beforeUnmount() {
            // Remove global events:
            this.$globalEvents.off(EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav);
            this.$globalEvents.removeEvent('click.global.course-unit-list', this.onClickGlobal);
        },

        data() {
            return {

                key: shortId(),

                featureRepository: inject(featureRepositoryKey),
                courseService: inject(courseServiceKey),
                unitService: inject(unitServiceKey),
                userService: inject(userServiceKey),

                /**
                 * @type Course|null
                 */
                course: null,

                /**
                 * @type Unit[]
                 */
                unitsInCourse: [],

                /**
                 * @type Unit
                 */
                selectedUnit: null,

                shortcuts: new Map([
                    ['Save.global.prevent', null],
                    ['Enter', this.onShortcutEnter],            // Open unit
                ])
            }
        },

        computed: {

            /**
             * @returns {{editCourse: PageHeaderButton}}
             */
            headerButtons() {
                return this.course === null ? {} : {
                    editCourse: new PageHeaderButton({
                        caption: trans('labels.edit'),
                        tooltip: 'buttons.courses.edit',
                        icon: 'icon_edit',
                        href: this.$root.route('courses.edit', {'course': this.course.uid}),
                        visible: this.courseIsEditable,
                    }),
                };
            },

            users() {
                return this.userService.users;
            },

            /**
             * Header page title
             *
             * @returns {String}
             */
            pageHeadline() {
                return this.course?.title || '';
            },

            pageSubtitle() {
                let subtitle = '';

                if (this.course === null) {
                    return null;
                }

                subtitle = moment(this.course.updated_at).format(trans('courses.edit.header.revision_date_format'));

                if (this.courseOwner !== null) {
                    subtitle = trans('courses.edit.header.subtitle_by', {
                        text: subtitle,
                        user: this.courseOwner.firstname + ' ' + this.courseOwner.lastname,
                    });
                }

                return subtitle;
            },

            /**
             * @returns {StatusLabelConfig[]}
             */
            pageHeaderLabels() {
                const labels = [];

                if (this.course === null) {
                    return [];
                }

                // Policy (if non-standard)
                if (this.course.policy !== UnitPermissionPolicyStandard.type)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'policy',
                            caption: this.course.policy
                        })
                    );
                }

                return labels;
            },


            courseOwner() {
                const user = this.userService.getUserByUid(this.course.owned_by);

                if (user === null && this.course.owned_by === window.currentUser.uid) {
                    return window.currentUser;
                }

                return user;
            },

            courseIsDraft() {
                return (
                    this.course !== null
                    && this.course.isDraft
                );
            },

            /**
             * @returns {boolean}
             */
            courseIsEditable() {
                return (
                    this.course !== null
                    && this.$gate.allows(Permission.ability(Permission.CoursesUpdate()), this.course)
                );
            },

            /**
             * @return {string[]} Units of the current revision or empty array when not yet loaded
             */
            units() {
                return this.course?.unit_uids || [];
            },

            /**
             * @return {boolean} true if the current revision has any units
             */
            hasUnits() {
                return this.units.length > 0;
            },

            /**
             * Loading state
             *
             * @returns {Boolean}
             */
            isLoading() {
                if (this.courseService.isLoading || this.userService.isLoading || this.unitService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * Saving state
             *
             * @returns {Boolean}
             */
            isSaving() {
                if (this.courseService.isSaving || this.userService.isSaving || this.unitService.isSaving) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            shouldShowUnitSidepanel() {
                return this.selectedUnit !== null;
            },
        },

        methods: {
            route,
            trans,
            /**
             * Fetch current course to edit and save it inside {@link course}.
             */
            fetchCourse() {
                this.courseService
                    .fetchCourse(this.initialCourseUid)
                    .catch(this.onErrorApi)
                    .then(course => {
                        this.course = course;
                        this.fetchUnitsForCourse();
                    });
            },

            /**
             * Opens the given course for editing
             * @param {Course} course
             */
            editCourse(course) {
                window.location.href = this.$root.route('courses.edit', {'course': course.uid});
                return this;
            },

            fetchUnitsForCourse() {
                // We use this.course.numberOfUnits as the per_page param as a workaround
                // until we support pagination in the list.
                this.courseService
                    .fetchUnitsForCourse(
                        this.course.uid,
                        this.course.numberOfUnits
                    )
                    .then(units => this.unitsInCourse = units)
                    .catch(this.onErrorApi)
                return this;
            },

            /**
             * Click handler for header navigation buttons that delegates the action to the button callback method
             *
             * @param {PageHeaderButton} buttonConfig
             */
            onClickHeaderNav(buttonConfig) {
                if (buttonConfig.callback === null) {
                    return this;
                }

                buttonConfig.callback.call(this, buttonConfig);
                return this;
            },

            /**
             * Launches the give unit.
             * @param {Unit} unit
             */
            onClickUnitLaunch(unit) {
                if (this.$gate.denies(Permission.ability(Permission.CoursesLaunchUnit()), this.course, unit)
                    || this.featureRepository.inactive(Feature.EntitlementDevicetypeWebapp)) {
                    return this;
                }

                window.location.href = this.$root.route('courses.units.launch', {'course': this.course.uid, 'unit': unit.uid});
                return this;
            },

            onBlurUnit(e) {
                if (
                    !this.$refs.unitList.contains(e.relatedTarget)
                    && !this.$refs.inspector.contains(e.relatedTarget)
                ) {
                    this.selectedUnit = null;
                }
                return this;
            },

            /**
             * Click event handler for unit
             * @param {Unit} unit
             */
            onClickUnit(unit) {
                this.selectUnit(unit);
                return this;
            },

            /**
             * Select a specific unit for editing in the side panel
             * @param {Unit} unit
             */
            selectUnit(unit) {
                if (unit === null) {
                    this.selectedUnit = null;
                    this.key = shortId();

                    return this;
                }

                // Only select the unit if it's not already selected:
                if (this.selectedUnit === null || this.selectedUnit.uid !== unit.uid) {
                    this.selectedUnit = new Unit(unit);
                    this.key = shortId();
                }
                return this;
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = () => {
                        this.courseHasChanged = false;
                        this.$root.forceLogout();
                    };
                }
                this.$root.showErrorDialog(error);
                return this;
            },

            /**
             * Click handler for global events
             *
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                if (
                    this.$globalEvents.isEventTargetDescendantOfSelector(e, '#layout-header, #layout-sidemenu, .disabled:not(.selected)', '#layout-inspector, .disabled') === true
                    || e.target.matches('#content') === true
                ) {
                    this.selectUnit(null);
                }

                return this;
            },

            /**
             * Shortcut handler for opening a unit
             */
            onShortcutEnter() {
              // Only open the unit if the focus is on the list:
              if (
                  this.selectedUnit
                  && (
                      this.$refs.unitList.contains(document.activeElement)
                      || document.activeElement.matches('.panels')
                  )
              )
              {
                  this.onClickUnitLaunch(this.selectedUnit);
              }
              return this;
            },
        },
    }
</script>

<style lang="scss" scoped>

    .unit-list {
        max-width: var(--container-max-width);
        min-width: 670px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .pagination {
        margin-top: 40px;
    }

    .btn-edit-unit {
        margin-top: 20px;
    }
</style>
