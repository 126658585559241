import AbstractDataObject from '@/Models/AbstractDataObject';
import type {Feature} from '@/Models/Features/Feature';

export type VoiceConfig = {
    name: string | null,
    speaking_style: string | null,
};

export default class AiDataObject extends AbstractDataObject {

    public knowledge: string;
    public voice: VoiceConfig;

    constructor(
        attributes: any,
        private getDefaultVoice: (locale?: string) => VoiceConfig,
        private defaultKnowledge: string,
        private entitlement: Feature,
        parent: AbstractDataObject | null = null,
    ) {
        super(parent);

        this.knowledge = typeof attributes?.knowledge === 'string' ? attributes.knowledge : defaultKnowledge;
        this.voice = typeof attributes?.voice === 'object' && attributes.voice !== null ?
            attributes.voice : getDefaultVoice();
    }

    // noinspection JSUnusedGlobalSymbols
    toJSON() {
        return {
            knowledge: this.knowledge,
            voice: this.voice,
        };
    }

    get isValid(): boolean {
        return (
            super.isValid
            && (this.knowledge.length > 0)
            && (typeof this.voice.name === 'string' && this.voice.name.length > 0)
            && (typeof this.voice.speaking_style === 'string' && this.voice.speaking_style.length > 0)
        );
    }

    get entitlementsNeeded(): Feature[] {
        return [this.entitlement];
    }

    resetToDefaultVoice(locale?: string): void {
        this.voice = this.getDefaultVoice(locale);
    }

    resetToDefaultKnowledge(): void {
        this.knowledge = this.defaultKnowledge;
    }

    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset knowledge to default:
        if (!this.knowledge || this.knowledge.trim() === '') {
            console.info(
                'AiDataObject->cleanUpData(): Resetting invalid knowledge to default.',
                this.knowledge,
                this
            );
            this.knowledge = this.defaultKnowledge;
            hasChanged = true;
        }

        // Reset voice to default:
        if (!this.voice || !this.voice.name || !this.voice.speaking_style) {
            console.info(
                'AiDataObject->cleanUpData(): Resetting invalid voice configuration to default.',
                this.voice,
                this
            );
            this.resetToDefaultVoice();
            hasChanged = true;
        }

        return hasChanged;
    }
}
