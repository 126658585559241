<template>
    <div class="launch-page">
        <template v-if="!showWebapp">
            <PageLmsXApiLaunchHeader />

            <PageLmsXApiLaunchStage
                :card-headline="unitTitle"
                :card-image-url="unitPreviewUrl"
                :card-text="unitDescription"
                :teaser-copy="teaserCopy"
                :teaser-headline="teaserHeadline"
                :unit-is-launchable="unitIsLaunchable"
                teaser-button-get-app-caption="lms_xapi.launch_page.teaser.button_get_app"
                teaser-button-headset-caption="lms_xapi.launch_page.teaser.button_headset"
                teaser-button-webapp-caption="lms_xapi.launch_page.teaser.button_webapp"
                @on-button-headset-clicked="openHeadsetInstructions"
                @on-button-get-app-clicked="showInstallOverlay"
                @on-button-webapp-clicked="openWebapp"
            />

            <PageLmsXApiLaunchHeadsetInstructions
                v-if="showInstallation"
                :url="url"
                :user-email="userEmail"
                :user-password="userPassword"
            />

            <PageLmsXApiLaunchFooter
                :help-url="helpUrl"
            />
        </template>
        <template v-else>
            <PageLmsXApiLaunchWebapp
                :access-token="accessToken"
                :is-preview="isPreview"
                :tenant-uid="tenantUid"
                :unit-revision-uid="unitRevisionUid"
                :unit-title="unitTitle"
                :unit-uid="unitUid"
                @on-button-open-in-headset="openHeadsetInstructions"
            />
        </template>
    </div>
</template>

<script>
import EventType from '@/Utility/EventType';
import PageLmsXApiLaunchHeader from '@/Vue/Lms/xapi/PageLmsXApiLaunchHeader';
import PageLmsXApiLaunchFooter from '@/Vue/Lms/xapi/PageLmsXApiLaunchFooter';
import PageLmsXApiLaunchStage from '@/Vue/Lms/xapi/PageLmsXApiLaunchStage';
import {inject} from 'vue';
import {featureRepositoryKey} from '@/Vue/Bootstrap/InjectionKeys';
import PageLmsXApiLaunchHeadsetInstructions from '@/Vue/Lms/xapi/PageLmsXApiLaunchHeadsetInstructions.vue';
import {trans} from '@/Utility/Helpers';
import PageLmsXApiLaunchWebapp from '@/Vue/Lms/xapi/PageLmsXApiLaunchWebapp.vue';

export default {
    name: 'PageLmsXApiLaunch',

    components: {
        PageLmsXApiLaunchWebapp,
        PageLmsXApiLaunchHeadsetInstructions,
        PageLmsXApiLaunchHeader,
        PageLmsXApiLaunchStage,
        PageLmsXApiLaunchFooter,
    },
    props: {
        unitTitle: {
            type: String,
            required: true,
        },
        unitDescription: {
            type: String,
            required: true,
        },
        unitPreviewUrl: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        userEmail: {
            type: String,
            required: true,
        },
        userPassword: {
            type: String,
            required: true,
        },
        helpUrl: {
            type: String,
            required: true,
        },
        unitIsLaunchable: {
            type: Boolean,
            default: false,
        },
        unitUid: {
            type: String,
            required: true,
        },
        unitRevisionUid: {
            type: String,
            required: true,
        },
        accessToken: {
            type: String,
            required: true,
        },
        isPreview: {
            type: Boolean,
            required: true,
        },
        tenantUid: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            featureRepository: inject(featureRepositoryKey),
            showInstallation: false,
            showWebapp: false,
        };
    },

    computed: {
        teaserHeadline() {
            return this.unitIsLaunchable
                ? trans('lms_xapi.launch_page.teaser.webapp.headline')
                : trans('lms_xapi.launch_page.teaser.app_only.headline');
        },

        teaserCopy() {
            return this.unitIsLaunchable
                ? trans('lms_xapi.launch_page.teaser.webapp.copy')
                : trans('lms_xapi.launch_page.teaser.app_only.copy');
        },
    },

    beforeMount() {
        this.showInstallation = !this.unitIsLaunchable;
    },

    methods: {
        trans,

        openWebapp() {
            this.showWebapp = true;
            this.showInstallation = false;
        },

        showInstallOverlay() {
            this.$globalEvents.emit(EventType.MODAL_INSTALL_APP_SHOW);
        },

        openHeadsetInstructions() {
            this.showWebapp = false;
            this.showInstallation = true;
        },
    },
};
</script>

<style lang="scss">

$xapi-launch-page-margin: 24px;
$xapi-launch-page-max-width: 1368px;

.launch-page {
    width: 100%;
    background-color: var(--background-color-white);
    overflow: auto;

    p, li {
        font-family: var(--font-family-condensed);
    }

    ul {
        margin-bottom: 1rem;
        list-style-position: inside;
    }

    p + button {
        margin-top: 16px;
    }

    .bg-color-primary {
        background-color: var(--color-primary);
    }

    .launch-page-row {
        display: flex;
        flex-wrap: wrap;
    }

    .launch-page-stage-row + footer .footer-stage {
        margin-top: 32px;
    }

    .launch-page-container {
        width: 100%;
        max-width: $xapi-launch-page-max-width;
        display: flex;
        margin: 0 auto;
        padding: 0 $xapi-launch-page-margin 0 $xapi-launch-page-margin;
    }
}

</style>
