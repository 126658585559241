<template>
    <div class="launch-page-webapp">
        <PageLmsXApiLaunchWebappHeader
            :title="unitTitle"
            @on-trigger-fullscreen="triggerFullscreen"
            @on-trigger-open-in-headset="stopWebapp"
            @on-toggle-controls="toggleControls"
        />
        <div class="launch-page-webapp-player">
            <Webapp
                ref="webapp"
                :access-token="accessToken"
                :add-modals="true"
                :is-preview="isPreview"
                :tenant-uid="tenantUid"
                :unit-revision-uid="unitRevisionUid"
                :unit-title="unitTitle"
                :unit-uid="unitUid"
                @on-webapp-stopped="onWebappStopped"
            />
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Webapp from '@/Vue/Tools/Webapp/Webapp.vue';
import PageLmsXApiLaunchWebappHeader from '@/Vue/Lms/xapi/PageLmsXApiLaunchWebappHeader.vue';

export default defineComponent({
    components: {
        Webapp,
        PageLmsXApiLaunchWebappHeader,
    },

    props: {
        unitTitle: {
            type: String,
            required: true,
        },
        unitUid: {
            type: String,
            required: true,
        },
        unitRevisionUid: {
            type: String,
            required: true,
        },
        accessToken: {
            type: String,
            required: true,
        },
        isPreview: {
            type: Boolean,
            required: true,
        },
        tenantUid: {
            type: String,
            required: true,
        },
    },

    emits: [
        'onButtonOpenInHeadset',
    ],

    computed: {
        webapp() {
            return this.$refs.webapp as typeof Webapp;
        }
    },

    methods: {
        triggerFullscreen() {
            this.webapp.triggerFullscreen();
        },

        toggleControls() {
            this.webapp.toggleControls();
        },

        stopWebapp() {
            this.webapp.unloadWebapp();
        },

        onWebappStopped() {
            this.$emit('onButtonOpenInHeadset');
        },
    },
});
</script>

<style lang="scss" scoped>
.launch-page-webapp {
    height: 100vh;
    width: 100%;
}

.launch-page-webapp-player {
    height: calc(100vh - 60px);
    width: 100%;
    padding: 0 24px 24px 24px;
}

@media only screen and (max-width: 768px) {
    .launch-page-webapp-player {
        height: calc(100vh - 65px);
    }
}
</style>
