import {cloneDeep} from 'lodash';
import AbstractDataObject from '@/Models/AbstractDataObject';
import { updateUidReferences, deleteUidReferences, getObjectUids, isUUID, parseColor, trans, uuid4 } from '@/Utility/Helpers';
import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';
import OverlayContentUtility from '@/Models/Unity/OverlayContentUtility';
import ComponentUtility from '@/Models/Unity/Components/ComponentUtility';
import TrainingScene from '@/Models/UnitData/Scenes/TrainingScene';
import Trigger from '@/Models/UnitData/Triggers/Trigger';
import HintObjectiveAssignment from '@/Models/UnitData/Triggers/Hints/HintObjectiveAssignment';
import HintSceneAssignment from '@/Models/UnitData/Triggers/Hints/HintSceneAssignment';
import TriggerType from '@/Models/UnitData/Triggers/TriggerType';
import Transform from '@/Models/Unity/Transform';
import LabelComponent from '@/Models/Unity/Components/LabelComponent';
import WaypointCollection from '@/Models/Unity/WaypointCollection';
import HotspotStyleType from '@/Models/UnitData/SceneObjects/HotspotStyleType';
import Command from '@/Models/UnitData/Commands/Command';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import AssetType from '@/Models/Asset/AssetType';
import OverlayButton from '@/Models/Unity/OverlayButton';
import OverlayContent from '@/Models/Unity/OverlayContent';
import Behaviour, {
    CollidableBehaviour,
    MovableBehaviour,
    PhysicsBehaviour,
    TeleportableBehaviour,
} from '@/Models/UnitData/Behaviours/Behaviour';
import UnitPermissionPolicy from '@/Models/Unit/UnitPermissionPolicy';
import PlaceableComponent from "@/Models/Unity/Components/PlaceableComponent";
import {Feature} from "@/Models/Features/Feature";
import {Posture} from "@/Models/UnitData/SceneObjects/Posture";
import TextToSpeechVoice from "@/Services/CognitiveServices/TextToSpeechVoice";
import UnitRevision from "@/Models/Unit/UnitRevision";
import UnitData from "@/Models/UnitData/UnitData";
import AiDataObject from '@/Models/UnitData/SceneObjects/AiDataObject';
import Variable from '@/Models/UnitData/Variables/Variables/Variable';
import VariableFactory from '@/Models/UnitData/Variables/Variables/VariableFactory';

/**
 * SceneObjectType + Subtype to SceneObject subclass mapping
 *
 * @param {String} type
 * @param {String} subtype
 * @returns {*|null}
 */
export function getSceneObjectClassFromType(type, subtype)
{
    const sceneObjectMapping = new Map ([
        [SceneObjectType.TypeOfAsset, new Map ([
            [SceneObjectType.Assets.CharacterModel3D.subtype, SceneObjectAssetCharacterModel3D],
            [SceneObjectType.Assets.EnvironmentImage.subtype, SceneObjectAssetEnvironmentImage],
            [SceneObjectType.Assets.EnvironmentModel3D.subtype, SceneObjectAssetEnvironmentModel3D],
            [SceneObjectType.Assets.EnvironmentVideo.subtype, SceneObjectAssetEnvironmentVideo],
            [SceneObjectType.Assets.Image.subtype, SceneObjectAssetImage],
            [SceneObjectType.Assets.Model3D.subtype, SceneObjectAssetModel3D],
            [SceneObjectType.Assets.Sound.subtype, SceneObjectAssetSound],
            [SceneObjectType.Assets.SoundTts.subtype, SceneObjectAssetTtsSound],
            [SceneObjectType.Assets.Text.subtype, SceneObjectAssetText],
            [SceneObjectType.Assets.Video.subtype, SceneObjectAssetVideo],
        ])],
        [SceneObjectType.TypeOfGroup, new Map([
            [SceneObjectType.Group.subtype, SceneObjectGroup],
        ])],
        [SceneObjectType.TypeOfHotspot, new Map([
            [SceneObjectType.Hotspots.Generic.subtype, SceneObjectHotspotGeneric],
            [SceneObjectType.Hotspots.Transparent.subtype, SceneObjectHotspotTransparentShape],
        ])],
        [SceneObjectType.TypeOfModule, new Map ([
            [SceneObjectType.Modules.Connection.subtype, SceneObjectModuleConnection],
            [SceneObjectType.Modules.Helper.subtype, SceneObjectModuleHelper],
            [SceneObjectType.Modules.Input.subtype, SceneObjectModuleInput],
            [SceneObjectType.Modules.Intro.subtype, SceneObjectModuleIntro],
            [SceneObjectType.Modules.Keypad.subtype, SceneObjectModuleKeypad],
            [SceneObjectType.Modules.Outro.subtype, SceneObjectModuleOutro],
            [SceneObjectType.Modules.Overlay.subtype, SceneObjectModuleOverlay],
            [SceneObjectType.Modules.Universal.subtype, SceneObjectModuleUniversal],
            [SceneObjectType.Modules.Variable.subtype, SceneObjectModuleVariable],
        ])],
    ]);

    return sceneObjectMapping.has(type) && sceneObjectMapping.get(type).has(subtype) ? sceneObjectMapping.get(type).get(subtype) : null;
}

export default class SceneObject extends AbstractDataObject
{
    static get constructorName() { return 'SceneObject'; }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        if (new.target === SceneObject) {
            throw new TypeError(`Cannot construct SceneObject instances directly`);
        }

        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Populate the model:
        this.uid = attributes.uid || uuid4();                               // Unique ID
        this.originalUid = this.uid;                                        // Original unique ID from which the object was duplicated (hidden)
        this.type = attributes.type;                                        // Type definition string
        this.subtype = attributes.subtype;                                  // Subtype definition string
        this.title = attributes.title || null;                              // Title or name of the scene object
        this.hidden_in_scenes = attributes.hidden_in_scenes || [];          // Whether the scene object is initially hidden in certain scenes (only for global scene objects)
        this.visible = (typeof attributes.visible === 'boolean') ? attributes.visible : true; // Initial visibility state when the scene is loaded

        // Convert child elements into their respective class instances:
        this.transform = this.initTransform(attributes);
        this.triggers = this.initTriggers(attributes);
        this.components = this.initComponents(attributes);
        this.behaviours = this.initBehaviours(attributes);
    }

    /**
     * Create a new SceneObject with the given SceneObjectType
     *
     * @param {SceneObjectType} sceneObjectType
     * @param {Object} attributes
     * @param parent
     * @returns {SceneObject}
     */
    static createWithType(sceneObjectType, attributes = null, parent = null)
    {
        if (!(attributes instanceof Object)) {attributes = {};}
        const sceneObjectClass = getSceneObjectClassFromType(sceneObjectType.type, sceneObjectType.subtype);

        // Merge default attributes:
        if (sceneObjectClass !== null && sceneObjectClass.defaultAttributes instanceof Object) {
            attributes = {
                ...sceneObjectClass.defaultAttributes, ...attributes
            };
        }

        // Enforce the title, type and subtype that are provided by sceneObjectType:
        attributes = {
            ...attributes,
            ...{
                title: attributes.title || sceneObjectType.title,
                type: sceneObjectType.type,
                subtype: sceneObjectType.subtype,
            }
        };

        return SceneObject.createFromAttributes(attributes, parent);
    }

    /**
     * Create a new SceneObject from given attributes
     *
     * @param {Object} attributes
     * @param parent
     * @returns {SceneObject}
     */
    static createFromAttributes(attributes = {}, parent = null)
    {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        const className = getSceneObjectClassFromType(clonedAttributes.type, clonedAttributes.subtype) || SceneObject;
        return new className(clonedAttributes, parent);
    }

    /**
     * Has this type of SceneObject reached its maximum allowed count globally or in a scene?
     *
     * @returns {Boolean}
     */
    get hasReachedMaxCount() {
        // No limitation is set if the scene object has no parent:
        const isGlobal = this.isGlobal;
        const parent = isGlobal ? this.getParent(UnitData) : this.getParent(TrainingScene);
        if (parent === null)
        {
            console.warn('SceneObject->hasReachedMaxCount(): Unable to check maximum count because parent is not set');
            return false;
        }
        const maxCount = isGlobal ? this.sceneObjectType.maxCountGlobal : this.sceneObjectType.maxCountPerScene;
        if (maxCount === null) {return false;}
        if (maxCount === 0) {return true;}
        const objects = isGlobal ? parent.allGlobalObjects : parent.allSceneObjects;
        return (objects.filter(o => o.type === this.type && o.subtype === this.subtype).length >= maxCount);
    }

    /**
     * Does the SceneObject have any behaviours?
     *
     * @returns {Boolean}
     */
    get hasBehaviours() {
        return (this.behaviours instanceof Array && this.behaviours.length > 0);
    }

    /**
     * Does the scene have any components?
     *
     * @returns {Boolean}
     */
    get hasComponents() {
        return (this.components instanceof Array && this.components.length > 0);
    }

    /**
     * Does the SceneObject have any triggers?
     *
     * @returns {Boolean}
     */
    get hasTriggers() {
        return (this.triggers instanceof Array && this.triggers.length > 0);
    }

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return 'icon_asset';
    }

    /**
     * Is this SceneObject child of a SceneObjectGroup?
     *
     * @returns {Boolean}
     */
    get isGroupChild() {
        return (this.parent && this.parent instanceof SceneObjectGroup);
    }

    /**
     * Check if the object is valid
     *
     * @returns {boolean}
     */
    get isValid() {
        // @NOTE: Override this method on subclasses to make sure a scene object only uses valid data
        // All triggers and behaviours must be valid:
        // @TODO: Validate components
        return this.triggers.every(c => c.isValid) && this.behaviours.every(b => b.isValid);
    }

    /**
     * Get the SceneObjectType
     *
     * @returns {SceneObjectType|null}
     */
    get sceneObjectType() {
        return SceneObjectType.findByType(this.type, this.subtype);
    }

    /**
     * Does the scene object support AI features
     * @returns {boolean}
     */
    get supportsAI() {
        return false;
    }

    /**
     * Get a list of supported behaviour types
     *
     * @returns {string[]}
     */
    get supportedBehaviours() {
        return [];
    }

    /**
     * Get a list of supported component types
     *
     * @returns {string[]}
     */
    get supportedComponents() {
        return [];
    }

    /**
     * Does the scene object support tags
     *
     * @returns {boolean}
     */
    get supportsTags() {
        return false;
    }

    /**
     * Does the scene object support changing the transform
     *
     * @returns {boolean}
     */
    get supportsTransform() {
        return false;
    }

    /**
     * Does the object support behaviours
     *
     * @returns {Boolean}
     */
    get supportsBehaviours() {
        return this.supportedBehaviours.length > 0;
    }

    /**
     * Get supported CommandTypes for this scene object
     *
     * @returns {CommandType[]}
     */
    get supportedCommandTypes() {
        return [...(this.sceneObjectType.commands || [])];
    }

    /**
     * Get supported TriggerTypes for this scene object
     *
     * @returns {TriggerType[]}
     */
    get supportedTriggerTypes() {
        return [...(this.sceneObjectType.triggers || [])];
    }

    /**
     * Does the SceneObject contain a specific command?
     *
     * @param {Command} command
     * @returns {Boolean}
     */
    hasCommand(command) {
        return (this.hasTriggers && this.triggers.find(t => t.hasCommand(command)));
    }

    /**
     * Does the SceneObject contain a specific trigger?
     *
     * @param {Trigger} trigger
     * @returns {Boolean}
     */
    hasTrigger(trigger) {
        return (this.hasTriggers && this.triggers.find(t => t.uid === trigger.uid));
    }

    /**
     * Get components by specific type
     *
     * @param {String} componentType
     * @returns {Component[]}
     */
    getComponentsByType(componentType)
    {
        return (this.components.length > 0) ? this.components.filter(c => typeof c.type === 'string' && c.type === componentType) : [];
    }

    /**
     * Check if the sceneObject is of a given type
     *
     * @param {SceneObjectType} sceneObjectType
     * @returns {Boolean}
     */
    typeOf(sceneObjectType)
    {
        return (sceneObjectType instanceof SceneObjectType && this.type === sceneObjectType.type && this.subtype === sceneObjectType.subtype);
    }

    /**
     * Initialize behaviours from given attributes
     *
     * @returns {Behaviour[]}
     */
    initBehaviours(attributes)
    {
        return (attributes.behaviours || []).map(b => Behaviour.createFromAttributes(b, this));
    }

    /**
     * Initialize components from given attributes
     *
     * @returns {Component[]}
     */
    initComponents(attributes)
    {
        return (attributes.components || []).map(c => ComponentUtility.castComponent(c));
    }

    /**
     * Initialize transform from given attributes
     *
     * @returns {Transform}
     */
    initTransform(attributes)
    {
        return new Transform(attributes.transform);
    }

    /**
     * Initialize triggers from given attributes
     *
     * @returns {Trigger[]}
     */
    initTriggers(attributes)
    {
        return (attributes.triggers || []).map(t => Trigger.createFromAttributes(t, this));
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {SceneObject}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = SceneObject.createFromAttributes(this, this.parent);
        duplicated.uid = uuid4();

        // Create new instances for child objects:
        duplicated.transform = duplicated.transform.duplicate(false);
        duplicated.triggers = (duplicated.triggers || []).map(t => t.duplicate(false));
        duplicated.components = (duplicated.components || []).map(c => c.duplicate(false));
        duplicated.behaviours = (duplicated.behaviours || []).map(c => c.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Clean up data (e.g. remove empty components)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = false;
        if (this.hasTriggers && this.triggers.filter(t => t.cleanUpData()).length > 0) {hasChanged = true;}
        if (this.hasBehaviours && this.behaviours.filter(b => b.cleanUpData()).length > 0) {hasChanged = true;}
        if (this.hasComponents)
        {
            const componentsCount = this.components.length;
            this.components = ComponentUtility.removeEmptyComponentsFromArray(this.components);
            if (componentsCount !== this.components.length) {hasChanged = true;}
        }

        // Clean up hidden_in_scenes property which should only be used for global objects:
        if (this.hidden_in_scenes.length > 0)
        {
            const parentUnitData = this.getParent(UnitData);

            // Check if parent unit data is set:
            if (parentUnitData === null) {throw new Error('SceneObject->cleanUpData(): Unable to clean up data because parent UnitData is not set.');}

            // Non-global objects (e.g. that have a scene as parent):
            if (!this.isGlobal)
            {
                console.info('SceneObject->cleanUpData(): Removing unused "hidden_in_scenes" property from non-global object.', this.hidden_in_scenes, this);
                this.hidden_in_scenes = [];
                hasChanged = true;
            }
            else
            {
                // Only allow existing scenes:
                const scenesUids = parentUnitData.scenes.map(s => s.uid);
                this.hidden_in_scenes = this.hidden_in_scenes.filter(uid => {
                    if (!scenesUids.includes(uid))
                    {
                        console.info('SceneObject->cleanUpData(): Removing unknown scene UID from "hidden_in_scenes" property.', uid, this);
                        hasChanged = true;
                        return false;
                    }
                    return true;
                });
            }
        }
        return hasChanged;
    }

    /**
     * Reset transform
     */
    resetTransform() {
        this.transform = new Transform();

        // Reset transforms on components:
        if (this.components.length > 0)
        {
            this.components.filter(c => c instanceof Object && c.transform instanceof Transform).forEach(c => c.transform = new Transform());
        }
        return this;
    }

    /**
     * Check if this (global) scene object is hidden in a given scene
     *
     * @param {TrainingScene|String} scene
     * @returns {Boolean}
     */
    isHiddenInScene(scene) {
        if (scene instanceof TrainingScene)
        {
            return (this.hidden_in_scenes.includes(scene.uid));
        }
        return (typeof scene === 'string') ? (this.hidden_in_scenes.includes(scene)) : false;
    }

    /**
     * Get triggers sorted by type
     *
     * @returns {Object<Trigger[]>}
     */
    getTriggersSortedByType() {
        const triggers = {};
        TriggerType.all.forEach(type => {
            triggers[type.type] = [];
        });
        this.triggers.forEach(t => {
            triggers[t.event].push(t);
        });
        return triggers;
    }

    /**
     * Merge a trigger into this scene object
     *
     * @param {Trigger} trigger                 // The trigger to be inserted
     * @param {Trigger} insertAfterTrigger      // Optional trigger after which the new trigger should be inserted
     * @returns {Trigger|null}                  // The successfully merged trigger, otherwise null
     */
    mergeTrigger(trigger, insertAfterTrigger = null) {

        // Cancel if the trigger's type is not allowed:
        const maxCountPerSceneObject = trigger.triggerType.maxCountPerSceneObject;
        if (maxCountPerSceneObject === 0 || !this.supportedTriggerTypes.map(t => t.type).includes(trigger.event))
        {
            return null;
        }

        // Continue with a duplicated instance of the trigger to make sure UIDs stay unique:
        const triggerToMerge = trigger.duplicate(true);
        triggerToMerge.parent = this;

        // Create a new trigger if multiple triggers of this type are allowed:
        if (!triggerToMerge.hasReachedMaxCount)
        {
            // Insert a duplicated instance of the trigger:
            const insertIndex = (insertAfterTrigger instanceof Trigger) ? (this.triggers.findIndex(t => t.uid === insertAfterTrigger.uid) + 1) || this.triggers.length : this.triggers.length;
            this.triggers.splice(insertIndex, 0, triggerToMerge);

            // Clean up data:
            this.cleanUpData();

            // Return the merged trigger:
            return triggerToMerge;
        }

        // Merge the trigger with the selected trigger or first match of the same type (unless it's the same trigger):
        const mergeTargetTrigger =
            (insertAfterTrigger instanceof Trigger
                && insertAfterTrigger.event === trigger.event
                && insertAfterTrigger.uid !== trigger.uid
                && insertAfterTrigger.originalUid !== trigger.originalUid
                )
            ? insertAfterTrigger
            : this.triggers.find(t => t.event === trigger.event
                && t.uid !== trigger.uid
                && t.originalUid !== trigger.originalUid
                ) || null;
        if (mergeTargetTrigger !== null)
        {
            // @NOTE: Not using triggerToMerge here since commands would be duplicated twice breaking TriggerInvokeCommands and TriggerCancelCommands!
            return mergeTargetTrigger.mergeTrigger(trigger);
        }

        // Do not merge if the same trigger or a copy of it already exists:
        if (this.triggers.some(t => [t.uid, t.originalUid].some(uid => [trigger.uid, trigger.originalUid].includes(uid))))
        {
            console.info('SceneObject->mergeTrigger(): A copy of the trigger exists already.');
            return null;
        }

        return null;
    }

    /**
     * Remove a given behaviour
     *
     * @param {Behaviour} behaviour
     */
    removeBehaviour(behaviour) {
        if (behaviour instanceof Behaviour && this.hasBehaviours)
        {
            const removeAtIndex = this.behaviours.findIndex(b => b.uid === behaviour.uid);
            if (removeAtIndex >= 0)
            {
                this.behaviours.splice(removeAtIndex, 1);

                // Delete all UID references across the entire unit:
                deleteUidReferences(this.getParent(UnitData), getObjectUids(behaviour));
            }
        }
        return this;
    }

    /**
     * Remove a given trigger
     *
     * @param {Trigger} trigger
     */
    removeTrigger(trigger) {
        if (trigger instanceof Trigger && this.hasTriggers)
        {
            const removeAtIndex = this.triggers.findIndex(t => t.uid === trigger.uid);
            if (removeAtIndex >= 0)
            {
                this.triggers.splice(removeAtIndex, 1);

                // Delete all UID references across the entire unit:
                deleteUidReferences(this.getParent(UnitData), getObjectUids(trigger));
            }
        }
        return this;
    }
}

/*
|--------------------------------------------------------------------------
| Asset Scene Objects
|--------------------------------------------------------------------------
*/

export class BaseSceneObjectAsset extends SceneObject
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        if (new.target === BaseSceneObjectAsset) {
            throw new TypeError(`Cannot construct BaseSceneObjectAsset instances directly`);
        }

        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['asset'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Additional attributes:
        this.asset_uid = attributes.asset_uid || null;
        this.asset = attributes.asset || null;

        // Get asset data from revision if it wasn't set:
        const parentUnitRevision = this.getParent(UnitRevision);
        if (this.asset === null && parentUnitRevision !== null)
        {
            this.asset = (parentUnitRevision.assets || {})[this.asset_uid] || null;
        }
    }

    /**
     * Get the AssetType
     *
     * @returns {AssetType}
     */
    get assetType()
    {
        return (this.asset !== null) ? this.asset.assetType : null;
    }

    /**
     * @inheritDoc
     */
    get clipboardTitle()
    {
        return `${this.assetType.title} "${this.title || this.asset?.title}"`;
    }

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon()
    {
        const assetType = AssetType.all.find(a => a.type === this.subtype) || null;
        return (assetType !== null) ? assetType.icon : super.icon;
    }

    /**
     * Is this asset an environment?
     *
     * @returns {Boolean}
     */
    get isEnvironmentAsset()
    {
        return (this.asset !== null && this.asset.isEnvironment === true) || (this.subtype.indexOf('environment') === 0);
    }

    /**
     * Check if the object is valid
     *
     * @returns {boolean}
     */
    get isValid() {

        // Invalid if no asset is assigned:
        if (this.asset === null) {
            return false;
        }

        let isAssetPolicyAllowedInUnit = false;
        const parentUnitRevision = this.getParent(UnitRevision);

        // Check if asset policy is allowed by the unit's policy
        if (parentUnitRevision?.parent?.policy) {
            isAssetPolicyAllowedInUnit = UnitPermissionPolicy.getPolicyForType(parentUnitRevision.parent.policy).isAssetPolicyAllowed(this.asset.policy);
        }

        return (
            isAssetPolicyAllowedInUnit
            && this.asset_uid !== null
            && super.isValid
        );
    }

    /**
     * Does this asset support previews?
     *
     * @returns {Boolean}
     */
    get supportsAssetPreview() {
        return this.assetType !== null && (this.assetType.supportsPreview || this.asset.supportsPreviewImage);
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {SceneObject}
     */
    duplicate(updateUidMapping = true)
    {
        const duplicated = super.duplicate(false);
        duplicated.asset = this.asset;

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {updateUidReferences(duplicated);}

        return duplicated;
    }
}

export class SceneObjectAssetEnvironmentImage extends BaseSceneObjectAsset
{
    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }
}

export class SceneObjectAssetEnvironmentModel3D extends BaseSceneObjectAsset {

    /** @inheritDoc */
    static get defaultAttributes() {
        return {
            'behaviours': [
                Behaviour.createWithType(CollidableBehaviour.Type, null, null),
            ],
        };
    }

    /** @inheritDoc */
    get supportedBehaviours()
    {
        return [
            CollidableBehaviour.Type,
            TeleportableBehaviour.Type,
        ];
    }

    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }
}

export class SceneObjectAssetEnvironmentVideo extends BaseSceneObjectAsset
{
    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }
}

export class SceneObjectAssetImage extends BaseSceneObjectAsset
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Additional attributes:
        this.tags = attributes.tags || [];
    }

    /** @inheritdoc */
    get supportsTags() {
        return true;
    }

    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }

    /**
     * @inheritDoc
     */
    get supportedBehaviours()
    {
        return [
            CollidableBehaviour.Type,
            MovableBehaviour.Type,
            PhysicsBehaviour.Type,
            TeleportableBehaviour.Type,
        ];
    }

    /** @inheritdoc */
    get supportedComponents() {
        return [
            PlaceableComponent.type,
        ];
    }
}

export class SceneObjectAssetModel3D extends BaseSceneObjectAsset
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Additional attributes:
        this.tags = attributes.tags || [];
    }

    /** @inheritDoc */
    static get defaultAttributes() {
        return {
            'behaviours': [
                Behaviour.createWithType(CollidableBehaviour.Type, null, null),
            ],
        };
    }

    /**
     * Initialize behaviours from given attributes
     *
     * @returns {Behaviour[]}
     */
    initBehaviours(attributes)
    {
        // @NOTE: Backwards compatibility for unit version <0.42.0: Convert draggable to behaviours
        if (typeof attributes.draggable === 'boolean' && attributes.draggable && attributes.hasOwnProperty('behaviours') === false)
        {
            attributes.behaviours = [
                Behaviour.createFromAttributes({type: 'physics'}, this),
                Behaviour.createFromAttributes({type: 'movable'}, this)
            ];
        }

        return super.initBehaviours(attributes);
    }

    /**
     * @inheritDoc
     */
    get supportedBehaviours()
    {
        return [
            CollidableBehaviour.Type,
            MovableBehaviour.Type,
            PhysicsBehaviour.Type,
            TeleportableBehaviour.Type,
        ];
    }

    /** @inheritdoc */
    get supportedComponents() {
        return [
            PlaceableComponent.type,
        ];
    }

    /** @inheritdoc */
    get supportsTags() {
        return true;
    }

    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }
}

/**
 * @implements ISceneObjectWithAi
 */
export class SceneObjectAssetCharacterModel3D extends SceneObjectAssetModel3D {
    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        this.ai = this.initAi(attributes);

        this.posture = attributes.posture || Posture.Standing;
    }

    /** @inheritdoc */
    get entitlementsNeeded()
    {
        return [
            ...super.entitlementsNeeded,
            ...this.ai.entitlementsNeeded,
        ];
    }

    /** @inheritdoc */
    get supportsAI() {
        return true;
    }

    get defaultKnowledge() {
        return "As a 3D character in a virtual training scenario, interact with users warmly and provide concise, brief answers. Limit yourself to 2 to 3 sentences.";
    }

    /**
     * Initialize AI from given attributes
     *
     * @returns {AiDataObject}
     */
    initAi(attributes) {
        if (attributes.ai instanceof AiDataObject) {
            return attributes.ai;
        } else {
            return new AiDataObject(
                attributes.ai,
                this.getDefaultVoice.bind(this),
                this.defaultKnowledge,
                Feature.EntitlementCharacterAiExtensions,
                this
            );
        }
    }

    /**
     * @param {string | undefined} locale
     * @returns {VoiceConfig}
     */
    getDefaultVoice(locale = 'en-US') {
        const defaultVoice = TextToSpeechVoice.allByLocale[locale].find(voice =>
            (this.asset?.isMale && voice.isMale || !this.asset?.isMale && !voice.isMale)
        ) || null;

        if (!defaultVoice) {
            console.warn('SceneObjectAssetCharacterModel3D->getDefaultVoice(): Could not find a default voice.', this);
        }
        return {
            'name': defaultVoice?.shortName || null,
            'speaking_style': defaultVoice?.styleList[0] || null,
        };
    }

    /** @inheritdoc */
    get isValid() {
        return super.isValid && this.ai.isValid;
    }

    /**
     * @inheritDoc
     */
    cleanUpData() {
        return super.cleanUpData() || this.ai.cleanUpData();
    }
}

export class SceneObjectAssetSound extends BaseSceneObjectAsset {}

export class SceneObjectAssetTtsSound extends BaseSceneObjectAsset {}

export class SceneObjectAssetText extends SceneObject
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Additional attributes:
        this.headline = attributes.headline || null;
        this.text = attributes.text || null;
        this.tags = attributes.tags || [];
    }

    /**
     * Get the AssetType
     *
     * @returns {AssetType}
     */
    get assetType()
    {
        return AssetType.Text;
    }

    /** @inheritdoc */
    get clipboardTitle()
    {
        const translatedTitle = AssetType.Text.title;
        return translatedTitle + (this.title && this.title !== translatedTitle ? ` "${this.title}"` : '');
    }

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon()
    {
        return AssetType.Text.icon;
    }

    /**
     * @inheritDoc
     */
    get supportedBehaviours()
    {
        return [
            CollidableBehaviour.Type,
            MovableBehaviour.Type,
            PhysicsBehaviour.Type,
            TeleportableBehaviour.Type,
        ];
    }

    /** @inheritdoc */
    get supportedComponents() {
        return [
            PlaceableComponent.type,
        ];
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        return ((typeof this.headline === 'string' && this.headline.length > 0) || (typeof this.text === 'string' && this.text.length > 0)) && super.isValid;
    }

    /** @inheritdoc */
    get supportsTags() {
        return true;
    }

    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }
}

export class SceneObjectAssetVideo extends BaseSceneObjectAsset
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Additional attributes:
        attributes.video = attributes.video || {};
        this.video = Object.assign({}, attributes.video, {
            autoplay: (typeof attributes.video.autoplay === 'boolean') ? attributes.video.autoplay : false
        });
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        return (this.video instanceof Object && typeof this.video.autoplay === 'boolean') && super.isValid;
    }

    /** @inheritdoc */
    get supportedComponents() {
        return [
            PlaceableComponent.type,
        ];
    }

    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }
}

/*
|--------------------------------------------------------------------------
| Group Scene Objects
|--------------------------------------------------------------------------
*/

export class SceneObjectGroup extends SceneObject
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Additional attributes:
        this.collapsed = (typeof attributes.collapsed === 'boolean') ? attributes.collapsed : false;    // Collapsed state

        // Convert child elements into their respective class instances:
        this.objects = this.initObjects(attributes);
    }

    /** @inheritdoc */
    get clipboardTitle()
    {
        const translatedTitle = trans('sceneobjects.group.title');
        return translatedTitle + (this.title && this.title !== translatedTitle ? ` "${this.title}"` : '');
    }

    /**
     * Get icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return 'icon_group';
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // All children objects must be valid:
        return this.objects.every(o => o.isValid) && super.isValid;
    }

    /**
     * Get count of all descendant/nested objects
     *
     * @returns {Number}
     */
    get objectsCount() {
        return this.objectsFlat.length;
    }

    /**
     * Get count of all descendant/nested objects excluding collapsed objects
     *
     * @returns {Number}
     */
    get objectsCountCollapsed() {
        return this.collapsed ? 0 : (this.objects.map(c => 1 + (c instanceof SceneObjectGroup ? c.objectsCountCollapsed : 0)).reduce((count, c) => count + c, 0));
    }

    /**
     * Get all descendant/nested objects as a flattened list
     *
     * @returns {SceneObject[]}
     */
    get objectsFlat() {
        return this.objects.reduce((a, child) => a.concat([child], child.objectsFlat || []), []);
    }

    /**
     * Does the SceneObject have any nested SceneObjects?
     *
     * @returns {Boolean}
     */
    get hasObjects() {
        return (this.objects instanceof Array && this.objects.length > 0);
    }

    /**
     * Does the group have a specific object?
     *
     * @param {SceneObject}
     * @returns {Boolean}
     */
    hasObject(object) {
        return (object !== null && this.objects.length > 0 && this.objects.find(o => Object.is(o, object) === true || (o instanceof SceneObject && o.uid === object.uid) || (o instanceof SceneObjectGroup && o.hasObject(object))) !== undefined);
    }

    /**
     * Does the SceneObjectGroup contain a specific trigger?
     *
     * @param {Trigger} trigger
     * @returns {Boolean}
     */
    hasTrigger(trigger) {
        return false;   // @NOTE: Currently no triggers on groups
    }

    /**
     * Initialize SceneObject objects from attributes
     *
     * @param {SceneObject[]} attributes
     * @returns {SceneObject[]}
     */
    initObjects(attributes)
    {
        return (attributes.objects || []).map(c => (c.type === SceneObjectType.TypeOfGroup) ? new SceneObjectGroup(c, this) : SceneObject.createFromAttributes(c, this));
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {SceneObject}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = super.duplicate(false);

        // Create new instances for child objects:
        duplicated.objects = duplicated.objects.filter(c => !(c.hasReachedMaxCount || false)).map(c => typeof c.duplicate === 'function' ? c.duplicate(false) : cloneDeep(c));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Clean up data (e.g. remove empty components)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (this.objects.filter(c => typeof c.cleanUpData === 'function' && c.cleanUpData()).length > 0) {hasChanged = true;}
        return hasChanged;
    }

    /**
     * Add a given object to this group
     *
     * @param {SceneObject} sceneObject         // The SceneObject to be inserted
     * @param {Number} index                    // Optional index at which the new object should be inserted
     * @returns {SceneObject|null}              // The successfully added SceneObject, otherwise null
     */
    addSceneObject(sceneObject, index = null) {

        // Set parent first since it's needed for hasReachedMaxCount and has to be set anyway when inserting:
        sceneObject.parent = this;

        // Cancel if the object's type is not allowed:
        if (sceneObject.hasReachedMaxCount) {return null;}

        // Merge objects from another group since we don't allow nested groups at the moment:
        if (sceneObject instanceof SceneObjectGroup)
        {
            return this.mergeGroup(sceneObject);
        }

        // Insert the object:
        this.objects.splice((typeof index === 'number' && index >= 0) ? index : this.objects.length, 0, sceneObject);

        // Clean up data:
        this.cleanUpData();

        // Update the objectives:
        this.getParent(TrainingScene)?.updateObjectives();

        return sceneObject;
    }

    /**
     * Merge another group into this group
     *
     * @param {SceneObjectGroup} sceneObjectGroup   // The SceneObjectGroup to be merged
     * @returns {SceneObjectGroup}
     */
    mergeGroup(sceneObjectGroup) {

        if (!(sceneObjectGroup instanceof SceneObjectGroup))
        {
            throw new TypeError('SceneObjectGroup->mergeGroup(): Parameter must be an instance of SceneObjectGroup');
        }

        // Do not insert forbidden objects in group:
        sceneObjectGroup.objects = sceneObjectGroup.objects.filter(so => {
            // Set parent first since it's needed for hasReachedMaxCount and has to be set anyway when inserting:
            so.parent = this;
            return !so.hasReachedMaxCount;
        });

        // Merge objects from the group:
        this.objects.splice.apply(
            this.objects,
            [
                (typeof index === 'number' && index >= 0) ? index : this.objects.length,
                0
            ].concat(sceneObjectGroup.objects)
        );
        return this;
    }

    /**
     * Remove a given object from this group or its descendants
     *
     * @NOTE: Since removeSceneObject is recursive, the UID references must only be deleted from the parent-most object!
     *        Any calls to removeSceneObject() on child elements therefore must use false for the deleteUidReferences parameter!
     *
     * @param {SceneObject} sceneObject
     * @param {Boolean} deleteReferences
     */
    removeSceneObject(sceneObject, deleteReferences = true) {

        // Get UIDs from the object and its children:
        const uids = (deleteReferences === true) ? getObjectUids(sceneObject) : [];

        // Remove object from the list of objects:
        this.objects = this.objects.filter(o => Object.is(o, sceneObject) === false || o.uid !== sceneObject.uid);
        this.objects.filter(o => o instanceof SceneObjectGroup).map(o => o.removeSceneObject(sceneObject, false));

        // Delete all UID references across the unit or other parents or this object:
        const parentTrainingScene = this.getParent(TrainingScene);
        if (deleteReferences === true)
        {
            deleteUidReferences(this.getParent(UnitData) || parentTrainingScene || this.parent || this, uids);
        }

        // Update the objectives:
        if (parentTrainingScene !== null)
        {
            parentTrainingScene.updateObjectives();
        }

        return this;
    }

    /**
     * Reset transforms
     */
    resetTransforms() {
        this.resetTransform();
        this.objects.filter(c => typeof c.resetTransform === 'function').forEach(c => c.resetTransform());
        return this;
    }
}

/*
|--------------------------------------------------------------------------
| Hotspot Scene Objects
|--------------------------------------------------------------------------
*/

export class BaseSceneObjectHotspot extends SceneObject
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        if (new.target === BaseSceneObjectHotspot) {
            throw new TypeError(`Cannot construct BaseSceneObjectHotspot instances directly`);
        }

        // Additional attributes:
        this.style = attributes.style || (this.supportedStyleTypes[0] || {type: null}).type;
        this.tags = attributes.tags || [];
    }

    /** @inheritdoc */
    get clipboardTitle()
    {
        const translatedTitle = trans('labels.hotspot');
        return translatedTitle + (this.title && this.title !== translatedTitle ? ` "${this.title}"` : '');
    }

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon()
    {
        const styleTemplate = this.styleType;
        return (styleTemplate !== null) ? styleTemplate.icon : 'icon_hotspot-default';
    }

    /**
     * @returns {HotspotStyleType}
     */
    get styleType() {
        return HotspotStyleType.all.find(s => s.type === this.style) || null;
    }

    /**
     * Get supported style types for this scene object
     *
     * @returns {HotspotStyleType[]}
     */
    get supportedStyleTypes() {
        return [...(this.sceneObjectType.styles || [])];
    }

    /**
     * Clean up data (e.g. reset invalid style)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        // Reset default style:
        const availableStyles = this.supportedStyleTypes;
        if ((this.style !== null && availableStyles.length === 0) || !availableStyles.map(s => s.type).includes(this.style))
        {
            console.info('BaseSceneObjectHotspot->cleanUpData(): Resetting invalid style to default value.', this.style, this);
            this.style = (availableStyles[0] || {type: null}).type;
            return true;
        }
        return hasChanged;
    }

    /**
     * Initialize behaviours from given attributes
     *
     * @returns {Behaviour[]}
     */
    initBehaviours(attributes)
    {
        // @NOTE: Backwards compatibility for unit version <=0.42.0: Convert the way it worked before to an actual Behaviour on the scene object
        if (attributes.hasOwnProperty('behaviours') === false && attributes.hasOwnProperty('uid'))
        {
            attributes.behaviours = [
                Behaviour.createFromAttributes({type: 'collidable', enabled: true}, this)
            ];
        }

        return super.initBehaviours(attributes);
    }

    /**
     * @inheritDoc
     */
    get supportedBehaviours()
    {
        return [
            CollidableBehaviour.Type,
            TeleportableBehaviour.Type,
        ];
    }

    get supportedComponents() {
        return [
            PlaceableComponent.type,
        ];
    }

    /** @inheritdoc */
    get supportsTags() {
        return true;
    }

    /** @inheritdoc */
    get supportsTransform() {
        return true;
    }
}

export class SceneObjectHotspotGeneric extends BaseSceneObjectHotspot
{
    /** @inheritdoc */
    get supportedComponents() {
        return [
            PlaceableComponent.type,
            LabelComponent.type,
        ];
    }
}

export class SceneObjectHotspotTransparentShape extends BaseSceneObjectHotspot
{
    /** @inheritdoc */
    get clipboardTitle()
    {
        const translatedTitle = trans('hotspots.transparent.title');
        return translatedTitle + (this.title && this.title !== translatedTitle ? ` "${this.title}"` : '');
    }

    /**
     * Clean up data (e.g. remove label components)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (this.hasComponents)
        {
            // Remove label components from the transparent shape object:
            const componentsCount = this.components.length;
            this.components = this.components.filter(c => c.type !== LabelComponent.type);
            if (componentsCount !== this.components.length) {hasChanged = true;}
        }
        return hasChanged;
    }
}

/*
|--------------------------------------------------------------------------
| Module Scene Objects
|--------------------------------------------------------------------------
*/

export class BaseSceneObjectModule extends SceneObject
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        if (new.target === BaseSceneObjectModule) {
            throw new TypeError(`Cannot construct BaseSceneObjectModule instances directly`);
        }
    }

    /** @inheritdoc */
    get clipboardTitle()
    {
        const translatedTitle = trans('labels.module');
        return translatedTitle + (this.title && this.title !== translatedTitle ? ` "${this.title}"` : '');
    }

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return 'icon_module';
    }
}

/*
|--------------------------------------------------------------------------
| Module Scene Objects - Connection
|--------------------------------------------------------------------------
*/

export class SceneObjectModuleConnection extends BaseSceneObjectModule
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Additional attributes:
        this.connections = cloneDeep(attributes.connections || []);        // Unity connections created by the DreamApp (list of objects with name and positions)
        this.connectables = Array.from(attributes.connectables || []);       // Unity connectables created by the DreamApp (list of UIDs)

        // Update triggers for the connections:
        this.updateTriggersForConnectionModule();
    }

    get hasConnectables()           {return (this.connectables instanceof Array && this.connectables.length > 0);}
    get hasConnections()            {return (this.connections instanceof Array && this.connections.length > 0);}

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return 'icon_connection';
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        return super.isValid; // @TODO: Validation for connection module
    }

    /**
     * Update triggers for connection
     */
    updateTriggersForConnectionModule()
    {
        // Make sure there is one trigger for each connection:
        let insertAtIndex = Math.max(0, this.triggers.length - Array.from(this.triggers).reverse().findIndex(t => t.typeOf(TriggerType.OnConnectionPathComplete)));
        const pathNames = [];
        this.connections.forEach((c, ci) => {
            pathNames[pathNames.length] = c.name;
            const existingTrigger = this.triggers.find(t => t.value !== null && t.value === c.name) || null;
            if (existingTrigger === null)
            {
                // Insert a new trigger:
                this.triggers.splice(insertAtIndex, 0,
                    Trigger.createWithType(
                        TriggerType.OnConnectionPathComplete,
                        {
                            value: c.name
                        },
                        this
                    )
                );
                ++insertAtIndex;
            }
        });

        // Remove triggers for deleted connections:
        this.triggers = this.triggers.filter(t => t.typeOf(TriggerType.OnConnectionPathComplete) === false || pathNames.indexOf(t.value) >= 0);

        return this;
    }
}

/*
|--------------------------------------------------------------------------
| Module Scene Objects - Helper
|--------------------------------------------------------------------------
*/

export class SceneObjectModuleHelper extends BaseSceneObjectModule
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Additional attributes:
        this.logo_uid = attributes.logo_uid || null;                         // UID of the associated logo image asset
        this.glow_color = parseColor(attributes.glow_color || window.appData.THEME.app.colors.main || null); // Default glow color

        // Convert child elements into their respective class instances:
        this.hints = this.initHints(attributes);                                // List of HintObjectiveAssignments
        this.when_all_completed_hints = this.initWhenAllCompletedHints(attributes);
        this.waypoints = this.initWaypoints(attributes);   // Unity waypoints created by the DreamApp
        this.ai = this.initAi(attributes);
    }

    /** @inheritDoc */
    static get defaultAttributes() {
        return {
            triggers: [
                Trigger.createWithType(
                    TriggerType.OnActivate,
                    {
                        commands: [
                            Command.createWithType(
                                CommandType.HelperWaypointGoTo,
                                {
                                    target: Command.TargetSelf
                                },
                                null
                            )
                        ]
                    },
                    null
                ),
            ],
        };
    }

    /** @inheritdoc */
    get clipboardTitle()
    {
        return `${trans('modules.helper.title')}`;
    }

    get hasHints()                      {return (this.hints instanceof Array && this.hints.length > 0);}
    get hasWhenAllCompletedHints()      {return (this.when_all_completed_hints instanceof Array && this.when_all_completed_hints.length > 0);}
    get hasWaypoints()                  {return (this.waypoints instanceof WaypointCollection && this.waypoints.hasWaypoints === true);}

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return 'icon_helper';
    }

    /**
     * Get the reactions
     *
     * @returns {Trigger[]}
     */
    get reactions() {
        return this.triggers.filter(t => t.typeOf(TriggerType.OnCue));
    }

    /**
     * Get all related commands that are referencing this SceneObject
     *
     * @returns {Command[]}
     */
    get relatedCommands() {
        const helperCommandTypes = CommandType.helperCommands.map(ct => ct.type);
        const allCommandsInUnit = this.getParent(UnitData)?.allCommands ?? [];

        return allCommandsInUnit.filter(commandInUnit =>
            helperCommandTypes.includes(commandInUnit.type) &&
            commandInUnit.target === this.uid &&
            commandInUnit.getParent(SceneObject) !== this
        );
    }

    /**
     * Check if the object is valid
     *
     * @returns {boolean}
     */
    get isValid() {
        return super.isValid && this.ai.isValid;// @TODO: #PRDA-5841 Validation for Helper module
    }

    /** @inheritdoc */
    get supportsAI() {
        return true;
    }

    get entitlementsNeeded() {
        return [
            ...super.entitlementsNeeded,
            ...this.ai.entitlementsNeeded,
        ];
    }

    /**
     * Initialize AI from given attributes
     *
     * @returns {AiDataObject}
     */
    initAi(attributes) {
        if (attributes.ai instanceof AiDataObject) {
            return attributes.ai;
        } else {
            return new AiDataObject(
                attributes.ai,
                this.getDefaultVoice.bind(this),
                this.defaultKnowledge,
                Feature.EntitlementHelperAiExtensions,
                this
            );
        }
    }

    /**
     * Initialize hints from given attributes
     *
     * @returns {HintObjectiveAssignment[]}
     */
    initHints(attributes)
    {
        return (attributes.hints || []).map(t => new HintObjectiveAssignment(t));
    }

    /**
     * Initialize hints from given attributes
     *
     * @returns {HintSceneAssignment[]}
     */
    initWhenAllCompletedHints(attributes)
    {
        return (attributes.when_all_completed_hints || []).map(t => new HintSceneAssignment(t));
    }

    /**
     * Initialize waypoints from given attributes
     *
     * @returns {WaypointCollection}
     */
    initWaypoints(attributes)
    {
        return new WaypointCollection(attributes.waypoints);
    }

    get defaultKnowledge() {
        return 'You are a virtual robot companion called "Helper Companion" and a supporter within a virtual reality training called "${unit.title}".\n\n'
            + 'Support users, as best you can to find their way through their learning session. Try to motivate users and formulate your answers in a friendly, humorous way.\n\n'
            + 'Limit yourself to 2 to 3 sentences.';
    }

    /**
     * @param {string | undefined} locale
     * @returns {VoiceConfig}
     */
    getDefaultVoice(locale = 'en-US') {
        if (locale === 'en-US') {
            // "legacy" voice for american helper
            return {
                name: 'en-US-JennyNeural',
                speaking_style: 'friendly'
            };
        }

        // non american voices use the first match
        const nonAmericanDefaultVoice = TextToSpeechVoice.allByLocale[locale][0];
        return {
            name: nonAmericanDefaultVoice.shortName,
            speaking_style: nonAmericanDefaultVoice.styleList[0],
        };
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {SceneObject}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = super.duplicate(false);

        duplicated.hints = (duplicated.hints || []).map(c => c.duplicate(false));
        duplicated.when_all_completed_hints = (duplicated.when_all_completed_hints || []).map(c => c.duplicate(false));
        duplicated.waypoints = duplicated.waypoints.duplicate(false);

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Duplicate hints for a new scene
     *
     * @param {TrainingScene} newScene
     */
    duplicateHintsForNewScene(newScene) {

        // Objective hints
        const hints = this.hints;
        newScene
            .allTriggers
            .forEach(
                trigger => hints
                    .filter(hint => hint.objective === trigger.originalUid)
                    .forEach(
                        hint => {
                            const duplicatedHint = hint.duplicate();
                            duplicatedHint.objective = trigger.uid;
                            this.hints.push(duplicatedHint);
                        }
                    )
            );

        // When all completed
        this.when_all_completed_hints
            .filter(hint => hint.scene_uid === newScene.originalUid)
            .forEach(
                hint => {
                    const duplicatedHint = hint.duplicate();
                    duplicatedHint.scene_uid = newScene.uid;
                    this.when_all_completed_hints.push(duplicatedHint);
                }
            );

        return this;
    }

    /**
     * Duplicate hints for a new SceneObject
     *
     * @param {SceneObject} newSceneObject
     */
    duplicateHintsForNewSceneObject(newSceneObject) {

        // Objective hints
        const triggers = (newSceneObject instanceof SceneObjectGroup) ? newSceneObject.objectsFlat.map(o => o.triggers || []).flat() : newSceneObject.triggers || [];
        const hints = this.hints;
        triggers.forEach(
            trigger => hints
                .filter(hint => hint.objective === trigger.originalUid)
                .forEach(
                    hint => {
                        const duplicatedHint = hint.duplicate();
                        duplicatedHint.objective = trigger.uid;
                        this.hints.push(duplicatedHint);
                    }
                )
        );

        return this;
    }

    /**
     * Clean up data (e.g. remove invalid hints and waypoints)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        const parentUnitData = this.getParent(UnitData);

        // Check if parent unit data is set:
        if (parentUnitData === null) {throw new Error('SceneObjectModuleHelper->cleanUpData(): Unable to clean up data because parent UnitData is not set.');}

        const scenesUids = parentUnitData.scenes.map(s => s.uid);
        const helperCuesUids = this.hasTriggers ? this.triggers.filter(t => t.event === TriggerType.OnCue.type).map(t => t.uid) : [];

        // Remove waypoints for non-existing scenes:
        if (this.hasWaypoints)
        {
            Object.keys(this.waypoints).filter(k => isUUID(k)).forEach(k => {
                if (!scenesUids.includes(k))
                {
                    console.info('SceneObjectModuleHelper->cleanUpData(): Removing waypoints for unknown scene.', k, this);
                    delete this.waypoints[k];
                    hasChanged = true;
                }
            });
        }

        // Remove all when_all_completed_hints that do not have a valid scene_uid or cue and that are not referencing an existing cue trigger on this sceneObject:
        if (this.hasWhenAllCompletedHints)
        {
            this.when_all_completed_hints = this.when_all_completed_hints.filter(completedHint => {
                if (completedHint.scene_uid === null || !scenesUids.includes(completedHint.scene_uid))
                {
                    console.info('SceneObjectModuleHelper->cleanUpData(): Removing hint for unknown scene.', completedHint.scene_uid, this);
                    hasChanged = true;
                    return false;
                }
                if (completedHint.cue !== null && !helperCuesUids.includes(completedHint.cue))
                {
                    console.info('SceneObjectModuleHelper->cleanUpData(): Removing hint for unknown reaction.', completedHint.cue, this);
                    hasChanged = true;
                    return false;
                }
                return true;
            });
        }

        // Remove all hints that do not have a valid objective or cue and that are not referencing an existing trigger on this sceneObject:
        if (this.hasHints)
        {
            const possibleObjectivesUids = parentUnitData.allTriggers.filter(t => t.is_objective).map(t => t.uid);
            this.hints = this.hints.filter(hint => {
                if (hint.objective === null || !possibleObjectivesUids.includes(hint.objective))
                {
                    console.info('SceneObjectModuleHelper->cleanUpData(): Removing hint for unknown objective.', hint.objective, this);
                    hasChanged = true;
                    return false;
                }
                if (hint.cue !== null && !helperCuesUids.includes(hint.cue))
                {
                    console.info('SceneObjectModuleHelper->cleanUpData(): Removing hint for unknown reaction.', hint.cue, this);
                    hasChanged = true;
                    return false;
                }
                return true;
            });
        }

        if (this.ai.cleanUpData()) {
            hasChanged = true;
        }

        return hasChanged;
    }
}

/**
 * @deprecated since version 8.0.0, use "SceneObjectModuleUniversal" instead, #PRDA-12501
 */
export class SceneObjectModuleInput extends BaseSceneObjectModule {}

/**
 * @deprecated since version 3.0.0, use "SceneObjectModuleInput" instead
 */
export class SceneObjectModuleKeypad extends SceneObjectModuleInput
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        // Module is deprecated, but can safely be replaced with the input module
        this.subtype = `${SceneObjectType.Modules.Input.subtype}`;
    }
}

/*
|--------------------------------------------------------------------------
| Module Scene Objects - Overlays
|--------------------------------------------------------------------------
*/

export class BaseSceneObjectModuleOverlay extends BaseSceneObjectModule
{
    constructor(attributes = {}, parent = null)
    {
        super(attributes, parent);

        if (new.target === BaseSceneObjectModuleOverlay) {
            throw new TypeError(`Cannot construct BaseSceneObjectModuleOverlay instances directly`);
        }

        // Convert child elements into their respective class instances:
        this.contents = this.initContents(attributes);
    }

    /**
     * Get supported content types for this scene object
     *
     * @returns {string[]}
     */
    get supportedContentTypes() {
        return [...(this.sceneObjectType.contents || [])];
    }

    get hasContents() {return (this.contents instanceof Array && this.contents.length > 0);}

    /**
     * Get icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return 'icon_overlay';
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // All contents must be valid:
        return this.hasContents && this.contents.every(c => c.isValid) && super.isValid;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {SceneObject}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = super.duplicate(false);

        duplicated.contents = duplicated.contents.map(c => c.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Initialize contents from given attributes
     *
     * @returns {Content[]}
     */
    initContents(attributes) {
        return (attributes.contents || []).map(c => OverlayContentUtility.castContent(c, this));
    }

    /**
     * Remove a given content
     *
     * @param {OverlayContent} content
     */
    removeContent(content) {
        if (content instanceof OverlayContent && this.hasContents)
        {
            const removeAtIndex = this.contents.findIndex(c => c.uid === content.uid);
            if (removeAtIndex >= 0)
            {
                this.contents.splice(removeAtIndex, 1);

                // Delete all UID references across the entire unit:
                deleteUidReferences(this.getParent(UnitData), getObjectUids(content));

                this.setButtons();
            }
        }
        return this;
    }

    /**
     * Set overlay buttons
     *
     * @param {Boolean} skipButtonsForLastContent
     */
    setButtons(skipButtonsForLastContent = false)
    {
        this.contents.forEach((c, i) => {

            // Clear the buttons array:
            c.buttons = [];

            // Previous/Next paging if there's at least two elements:
            if (this.contents.length >= 2)
            {
                // Previous:
                if (i >= 1 && i < (this.contents.length - 1))
                {
                    c.buttons.push(new OverlayButton({
                        type: OverlayButton.Type.Previous,
                        commands: [
                            Command.createWithType(CommandType.OverlayPrevious)
                        ],
                    }));
                }

                // Next:
                if (i < (this.contents.length - 1))
                {
                    c.buttons.push(new OverlayButton({
                        type: OverlayButton.Type.Next,
                        style: OverlayButton.Style.Primary,
                        commands: [
                            Command.createWithType(CommandType.OverlayNext)
                        ],
                    }));
                }
            }

            // Last content element:
            if (!skipButtonsForLastContent && i === (this.contents.length - 1))
            {
                const buttonsForLastContent = this.buttonsForLastContent();

                for (let ii = 0; ii < buttonsForLastContent.length; ii++) {
                    c.buttons.push(buttonsForLastContent[ii]);
                }
            }
        });
        return this;
    }

    buttonsForLastContent() {
        return [];
    }
}

/**
 * @deprecated since version 8.0.0, use "SceneObjectModuleOverlay" instead, #PRDA-12536
 */
export class SceneObjectModuleIntro extends BaseSceneObjectModuleOverlay
{
    setButtons(skipButtonsForLastContent = false)
    {
        super.setButtons(true);

        if (this.contents.length > 0)
        {
            // Restart intro button (only if there's multiple contents):
            if (this.contents.length >= 2)
            {
                this.contents[this.contents.length - 1].buttons.push(new OverlayButton({
                    type: OverlayButton.Type.IntroRestart,
                    commands: [
                        Command.createWithType(CommandType.OverlayReset)
                    ],
                }));
            }

            const buttons = this.buttonsForLastContent();
            for (let i = 0; i < buttons.length; i++) {
                this.contents[this.contents.length - 1].buttons.push(buttons[i]);
            }
        }

        return this;
    }

    buttonsForLastContent()
    {
        const buttons = [];

        // Start button:
        buttons.push(new OverlayButton({
            type: OverlayButton.Type.Start,
            style: OverlayButton.Style.Primary,
            commands: [
                Command.createWithType(CommandType.OverlayHide)
            ],
        }));

        return buttons;
    }
}

/**
 * @deprecated since version 8.0.0, use "SceneObjectModuleOverlay" instead, #PRDA-12536
 */
export class SceneObjectModuleOutro extends BaseSceneObjectModuleOverlay
{
    buttonsForLastContent()
    {
        const buttons = [];

        // Restart button:
        buttons.push(new OverlayButton({
            type: OverlayButton.Type.TrainingRestart,
            commands: [
                Command.createWithType(CommandType.UnitReset)
            ],
        }));

        // Okay button:
        buttons.push(new OverlayButton({
            type: OverlayButton.Type.Okay,
            style: OverlayButton.Style.Primary,
            commands: [
                Command.createWithType(CommandType.OverlayHide)
            ],
        }));

        return buttons;
    }
}

export class SceneObjectModuleOverlay extends BaseSceneObjectModuleOverlay
{
    buttonsForLastContent()
    {
        const buttons = [];

        // Okay button:
        buttons.push(new OverlayButton({
            type: OverlayButton.Type.Okay,
            style: OverlayButton.Style.Primary,
            commands: [
                Command.createWithType(CommandType.OverlayHide)
            ],
        }));

        return buttons;
    }
}

/*
|--------------------------------------------------------------------------
| Module Scene Objects - Logic
|--------------------------------------------------------------------------
*/

export class SceneObjectModuleVariable extends BaseSceneObjectModule
{

    static get MaxAllowedVariables() {
        return 30;
    }

    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        this.variables = this.initVariables(attributes.variables);
    }

    /**
     * Initialize variables from given attributes
     *
     * @returns {Variable<any>[]}
     */
    initVariables(arrayOfVariables) {
        return (arrayOfVariables || []).map(v => VariableFactory.getVariableForData(v, this)).filter(v => v !== null);
    }

    get hasVariables() {
        return (this.variables instanceof Array && this.variables.length > 0);
    }

    /**
     * Get the icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return 'icon_variable';
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        return this.hasVariables &&
            this.variables.every(v => v.isValid) &&
            super.isValid;
    }

    /**
     * Get supported variable types for this scene object
     *
     * @returns {(Variable.Type)[]}
     */
    get supportedVariableTypes() {
        return [...(this.sceneObjectType.variables || [])];
    }

    /**
     * Looks for a variable inside this module with the given uid.
     * @param {String} uid
     * @return {CommandTargetType.Variable|null} Variable with the given uid or null if it could not be found.
     */
    getVariable(uid) {
        return this.variables.find(variable => variable.uid === uid) || null;
    }

    /**
     * @param {String} uid
     * @return {boolean} True if a variable with the given uid exists inside this module.
     */
    hasVariable(uid) {
        return this.variables.some(variable => variable.uid === uid);
    }

    /**
     * Remove a given variable
     *
     * @param {Variable} variable
     */
    removeVariable(variable) {
        if (variable instanceof Variable && this.hasVariables)
        {
            const removeAtIndex = this.variables.findIndex(v => v.uid === variable.uid);
            if (removeAtIndex >= 0)
            {
                this.variables.splice(removeAtIndex, 1);

                // Delete all UID references across the entire unit:
                deleteUidReferences(this.getParent(UnitData), getObjectUids(variable));
            }
        }
        return this;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {SceneObject}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = super.duplicate(false);

        duplicated.variables = duplicated.variables.map(v => v.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (this.variables.filter(v => v.cleanUpData()).length > 0) {hasChanged = true;}
        return hasChanged;
    }
}

export class SceneObjectModuleUniversal extends BaseSceneObjectModule {}
